
import React, { Component } from 'react'
import  BackgroundImage from '../../images/bg_small.jpg'
import  Logo from '../../images/darkgreylogo.png'
import  WhatsappLogo from '../../images/whatsapplogo.png'
import  InstagramLogo from '../../images/instalogo.png'
import  FacebookLogo from '../../images/facebooklogo.png'
import  EmailLogo from '../../images/emaillogo.png'
import './styles.scss';


class Header extends Component {

  handleClick = event => {
    var link;
    if ('email' !== event.currentTarget.id) {
    switch(event.currentTarget.id){
      case 'printImages': link = 'https://www.jumbomail.me/l/he/page/ncs'; break;
      case 'digitalAlbum': link = 'https://abucastro.lyncpix.com/loginnew.php?language=hebrew'; break;
      case 'whatsapp': link = 'https://wa.me/+972538236483'; break;
      case 'insta': link = 'https://www.instagram.com/pictureit.digital/'; break;
      case 'facebook': link = 'https://www.facebook.com/pictureit.digital'; break;
    }
    window.open(
        link,
        '_blank'
    );
  } else {
    window.location.href = 'mailto:ncsdigital@gmail.com';
  }
  };
    render() {
      return (
        <div className='mainHeader'>
          <div className='navBar'>

              <div className='headerLogos'>
                <img id='whatsapp' className='logo' onClick={this.handleClick}src={WhatsappLogo}></img>
                <img id='insta' className='logo' onClick={this.handleClick}src={InstagramLogo}></img>
                <img id='facebook' className='logo' onClick={this.handleClick}src={FacebookLogo}></img>
                <img id='email' className='logo' onClick={this.handleClick}src={EmailLogo}></img>
              </div>
              <div className='headerOptions'>
              <div id='printImages' className='option' onClick={this.handleClick}>הדפסת תמונות</div>
              <div id='digitalAlbum' className='option' onClick={this.handleClick}>אלבום דיגיטלי</div>    
              </div>
            <img className = 'pictureItLogo'src={Logo}></img>
          </div>
          <img className="bgImage" src = {BackgroundImage} />
        </div>
      )
    }
  }

export default Header