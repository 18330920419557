
import React, { Component } from 'react'
import './styles.scss';
import Header from '../../components/header/header'
import ClickableBox from '../../components/clickableBox/clickableBox'
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';


class Home extends Component {
    render() {
      return (
        <div className='mainHome'>
          <Header />
            <div className='bottomPart'>

                <div className='contactInfo'>
                  <div className='contactLine'>
                    <div className='text'>04-6460350</div>
                    <PhoneIcon className='logo'/>
                  </div>

                  <div className='contactLine'>
                    <div className='text'>053-8236483</div>
                    <WhatsAppIcon className='logo'/>
                  </div>

                  <div className='contactLine'>
                    <div className='text'>pictureit.digital</div>
                    <InstagramIcon className='logo'/>
                  </div>

                  <div className='contactLine'>
                    <div className='text'>ncsdigital@gmail.com</div>
                    <EmailIcon className='logo'/>
                  </div>
                </div>

                
                <div className='boxes'>
                    <ClickableBox box = {'createAlbum'} externalLink ={'https://abucastro.lyncpix.com/loginnew.php?language=hebrew'} />
                    <ClickableBox box = {'printImages'} externalLink={'https://www.jumbomail.me/l/he/page/ncs'}/>
                </div>

            </div>

        </div>
      )
    }
  }

export default Home